@import 'style.scss';

$sideMenuWidthCollapsed: 68px;
$sideMenuWidthExpanded: 188px;
$topMenuHeight: 58px;

.sidenav{
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: $sideMenuWidthCollapsed;
    // background-color: blueviolet;
    display: flex;
    flex-direction: column;
    justify-content: space-between;       
    transition: 0.5s; 
    padding-top: 5px;       
    padding-bottom: 5px; 

    padding-right: 5px;
    padding-left: 5px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 200;
    
    background-color: var(--side-menu-color);

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;    

    .side_menu_icon{
        display: block;
        margin-left: 10px;       
        margin-right: 10px;                    
        padding: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        fill: white;   
        transition: 0.5s all;  
        
        border-radius: 10px;

        svg{
            fill: white;
            transition: 0.5s; 
        }
    }

    .side_menu_burger_rotated{
        svg{
            transform: rotateZ(90deg);
        }
    }

    .active{              
        background-color: var(--side-menu-active-background);
        svg{
            fill: white;
        }      
        label{
            color: white;
        }  
    }

    label{
        white-space: nowrap;
        overflow: hidden;
        transition: 0.5s opacity;
        position: absolute;                
        padding-left: 15px;
        margin-top: 2px;
        opacity: 0;
        cursor: pointer;
        color: white;
        pointer-events: none;
    }
}

.sidenav_expanded{
    width: $sideMenuWidthExpanded;    
    a{
        label{
            opacity: 1;
            pointer-events:initial;
        }
    }
}

.container_side_nav_collapsed{
    transition: 0.5s; 
    margin-left: $sideMenuWidthCollapsed;
}
.container_side_nav_expanded{
    transition: 0.5s; 
    margin-left: $sideMenuWidthExpanded;
}

.top_menu{        
    position: fixed;
    top: 0;    
    height: $topMenuHeight;  
    width: 100%;
    overflow: hidden;
    z-index: 100;
    background-color: var(--top-menu-background);

    .right_box
    {
        position: fixed;
        top: 0;
        right: 15px;
        margin-top: 9px;
        margin-bottom: 9px; 

        @keyframes blink {
            0% {
                opacity: 0.25;  
            }
            50%{
                opacity: 1;  
            }
            100% {
                opacity: 0.25;  
            }
        }

        .notifications_icons{
            fill: #2c3e50;
            color: #2c3e50;
            transition: 1s all;
            animation: blink 3s infinite;
            margin-right: 10px;
            cursor: pointer;
        }
    }
    
    .avatar{
        border-radius: 100%;
        height: 40px;
        width: 40px;
    }
}

.app_parent{
    margin-top: $topMenuHeight; 
    padding-top: 15px;   
    background-color: var(--background-color);
    border-top:rgba(41, 128, 185, 0.25) 1px solid;
    
}

.avatar{
    border-radius: 100%;
    height: 40px;
    width: 40px;
    margin-left: 10px;
}
